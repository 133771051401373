.aml-single {
  &__main {
    position: relative;
    padding: 40px 40px 40px 24px;
    background: $gray-1;
    border-radius: 8px;
    border: 1px solid $gray-4;
  }
  &__header {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    &__top {
      display: flex;
      align-items: center;
    }
    &__icon {
      & > img {
        width: 40px;
        height: 40px;
      }
    }
    &__naming {
      margin-left: 16px;
      display: flex;
      flex-direction: column;

      &__top {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
    &__name {
      @include Ellipsis;
      margin-right: 8px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      max-width: 284px;
    }
    &__source {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-7;
    }
    &__data {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__risk {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      &__icon {
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }
    }
    &__last-check {
      min-height: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray-7;
    }
    &__bottom {
      margin-left: 57px;
      display: flex;
      flex-direction: row;
    }
    &__risk-rising {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $pink;
    }
    &__check-for-aml {
      margin-left: auto;
      width: 170px;
      height: 40px;
      margin-top: 16px;
      font-size: 14px;
      line-height: 20px;
      &.default-btn.lettuce:focus {
        opacity: 1;
      }
      & .bn-dropdown__overlay {
        left: auto !important;
        right: 0 !important;
      }
    }
    &__actions {
      z-index: 1;
      position: absolute;
      right: 24px;
      bottom: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__copy {
      height: 24px;
      padding: 0;
      margin-left: 24px;
    }
    &__download {
      color: $gray-8;
      svg, path {
        fill: $gray-8;
      }
    }
  }
  &__content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  &__radius-pie {
    width: 256px;
    height: 256px;
  }
  &__sources {
    margin-top: 30px;
    margin-left: 60px;

    &__table {
      font-weight: 400;
      line-height: 22px;
      font-size: 14px;
      color: $gray-9;
    }

    &__title {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray-8;
    }

    tr:not(:last-child) {
      td {
        padding-bottom: 8px;
      }
    }

    &__name {
      width: 230px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      &.head {
        padding-bottom: 9px !important;
        padding-left: 0;
        font-weight: 500;

        &:after {
          content: none;
        }
      }
      &.isSource {
        width: 120px;
      }
      &:after {
        content: "";
        right: 0;
        top: 7px;
        position: absolute;
        width: 1px;
        height: 12px;
        border-right: 1px solid $gray-5;
      }

    }
    &__ratio {
      vertical-align: text-top;
      padding-left: 20px;
      padding-right: 16px;
      color: $gray-8;
      text-align: end;
      &.head {
        font-weight: 500;
        padding-bottom: 9px !important;
      }
    }
  }
  &__history {
    margin-top: 40px;
    &.archive {
      margin-top: 0;
    }
    &__title {
      padding-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;
      color: $gray-8;
      margin-bottom: 8px;
      &.archive {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: $gray-9;
        margin-bottom: 16px;
      }
    }
    &__card {
      background: $gray-1;
      border: 1px solid $gray-4;
      border-radius: 8px;
      margin-bottom: 16px;

      &__icon-is-active {
        transform: rotate(180deg);
        &, path {
          fill: $main
        }
      }
      & .ant-collapse-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        svg {
          // margin-left: auto;
        }
      }
      & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 20px 16px 16px 24px;
      }
      & .aml-single__header__actions {
        bottom: 12px;
      }
      &__header {
        width: 100%;
        margin-right: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__datetime {
          display: flex;
          flex-direction: column;
        }
        &__date {
          font-size: 16px;
          line-height: 22px;
        }
        &__time {
          font-size: 14px;
          line-height: 22px;
          color: $gray-7;
        }
        &__naming {
          margin-left: 56px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        &__name {
          margin-right: auto;
          font-size: 16px;
          line-height: 22px;
        }
        &__subnmae {
          margin-right: auto;
          max-width: 418px;
          font-size: 16px;
          line-height: 22px;
          color: $gray-7;
        }
        &__risk {
          display: flex;
          align-items: center;
          margin-left: auto;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          min-width: 90px;
          &__icon {
            margin-right: 6px;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 448px;
      background: $gray-1;
      border: 1px solid $gray-4;
      border-radius: 8px;
    }
    &__skeleton {
      display: flex;
      flex-direction: column;

      .ant-skeleton-element {
        margin-bottom: 16px;
      }
      .ant-skeleton-button {
        border-radius: 8px;
        width: 100%;
        height: 80px;
      }
    }
    &__show-more {
      width: 100%;
      height: 40px;

      &__wrapper {
        margin-top: 16px;
        width: 100%;
      }
      &__skeleton {
        height: 40px !important;
        width: 100% !important;
        border: 0.5px solid $gray-5;
      }
    }
  }
  &__manual-checks {
    &__title {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__form {
      display: flex;
      flex-direction: row;
      //align-items: center;
      align-items: flex-start;
      justify-content: space-between;
      &__blockchain {
        margin: 0;
        height: 40px;
        width: 282px;
        .ant-select-selector {
          height: 38px !important;

          .ant-select-selection-search {
            display: flex;
            align-items: center;
          }
          .ant-select-selection-item {
            display: flex;
            align-items: center;
          }
          .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
          }
        }
      }
      &__address {
        margin-top: 0;
        height: 40px;
        width: 486px;
      }
      &__submit {
        height: 40px;
        width: 180px;

        & .bn-dropdown__overlay {
          left: auto !important;
          right: 0 !important;
        }
      }
      .field-wrapper:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .aml-single {
    &__manual-checks {
      &__form {
        .field-wrapper {
          width: 100%;
        }
        flex-direction: column;
        &__blockchain {
          width: 100%;
          margin-bottom: 16px;
        }
        &__address {
          width: 100%;
          margin-bottom: 16px;
        }
        &__submit {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: $lg-media) {
  .aml-single {
    &__radius-pie {
      width: 160px;
      height: 160px;
    }
  }
}
@media screen and (max-width: $more-md-media) {
  .aml-single {
    &__sources {
      margin-left: 4px;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .aml-single {
    &__main {
      padding: 0;
      background: transparent;
      border: none;
    }
    &__header {
      margin-left: 0;
      &__top {
      }
      &__data {
        display: flex;
        flex-direction: row;
        margin-left: 0;
      }
      &__risk {
        margin-left: 0;
        margin-right: 8px;
        &.wallet-history {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &__last-check {
        font-size: 12px;
        line-height: 20px;
        color: $gray-7
      }
      &__bottom {
        display: flex;
        flex-direction: column;
        margin-left: 0;
      }
      &__risk-rising {
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        span {
          background: #E8E9F0;
          border-radius: 40px;
          padding: 0 7px;
        }
        &.empty {
          display: none;
        }
        &.overflow {
          height: auto;
          padding: 0px 20px;
        }
      }
      &__check-for-aml {
        width: 100%;
      }
      &__actions {
        left: 16px;
        right: 16px;
        bottom: 0;
        justify-content: space-between;
      }
    }
    &__content {
      padding-bottom: 66px;
      margin-top: 16px;
      flex-direction: column;
      justify-content: center;
    }
    &__radius-pie {
      margin: 0 auto;
      width: 256px;
      height: 256px;
    }
    &__sources {
      width: 256px;
      margin: 0 auto;
      //margin-top: 0;
      //margin-left: 0;
    }
    &__history {
      margin-top: 24px;
      &__card {
        margin-bottom: 8px;
        &__header {
          cursor: pointer;
          padding: 20px 8px 16px 16px;
          &__date {
            font-size: 14px;
            line-height: 22px;
          }
          &__time {
            font-size: 12px;
            line-height: 17px;
            color: $gray-7;
          }
          &__naming {
            margin-left: 16px;
          }
          &__name {
            font-size: 14px;
          }
          &__subnmae {
            font-size: 14px;
          }
          &__risk {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            min-width: 94px;
            &__icon {
              margin-right: 4px;
            }
          }
        }
      }
      &__empty {
        border: none;
        background: none;
      }
      &__title {
        padding-left: 16px;
      }
    }
  }
}
