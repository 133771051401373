.aml {
  @include Scroll;
  display: flex;
  &-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  &-title_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
  &-title {
    margin-right: 10px;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
  }
  &-count {
    @include FlexCenter;
    padding: 0 7px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 50px;
    background: $gray-4;
    &.no-checks {
      cursor: pointer;
      color: $red-6;
    }
  }
  &-content {
    display: flex;
    padding-left: 24px;
    justify-content: center;
    max-width: calc(100% - 315px);
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: $gray-9;
  }
  &-sidebar {
    &__additional {
      margin-top: 60px;
    }
    &__manual-checks, &__archive {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 40px;
      background: $gray-1;
      border: 1px solid $gray-4;
      border-radius: 4px;
      padding-right: 22px;
      padding-left: 16px;
      @include Transition;
      &.active {
        background: $gray-2;
        border: 1px solid $main;
        & .title {
          color: $main;
        }
        svg, path {
          fill: $main;
        }
      }
      & .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-left: 24px;
      }
      & .count {
        z-index: 0;
        position: relative;
        margin-left: auto;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        flex: 0 0 auto;
        min-width: 16px;
        height: 16px;
        background: #e8e9f0;
        border-radius: 12px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
      }
    }
    &__manual-checks {
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &__archive {
      margin-top: 16px;
      svg {
        fill: $gray-8;
        margin-left: 4px;
        width: 20px;
        height: 20px;
        path {
          fill: $gray-8;
        }
      }
      & .title {
        margin-left: 16px;
        color: $gray-8;
      }
    }
  }
  &-list_wrapper {
    display: flex;
    width: 384px;
    max-height: 100%;
  }
  &-list_line {
    width: 1px;
    background: $gray-4;
  }
  &-list {
    position: relative;
    max-height: 302px;
    width: 100%;
    min-height: 60px;
    &__checks {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray-8;
      }
    }
    &.empty {
      min-height: 128px;
      overflow-y: initial;
      .aml-btn {
        margin-top: 0;
      }
    }
    &.scroll {
      overflow-y: scroll;
    }
    &.borderTop {
      border-top: 1px solid $gray-4;
    }
    &.borderBottom {
      border-bottom: 1px solid $gray-4;
    }
    .empty-text {
      padding: 0 20px;
      margin-top: 40px;
      text-align: center;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 14px 16px 14px 14px;
    width: 360px;
    height: 60px;
    background: $gray-1;
    border-radius: 4px;
    border: 1px solid $gray-4;
    cursor: pointer;
    transition: border 0.3s ease;
    &:last-child {
      margin-bottom: 0;
    }
    &-logo {
      margin-right: 8px;
      padding: 4px;
      width: 40px;
      height: 40px;
    }
    &__naming {
      display: flex;
      flex-direction: column;
    }
    &__name {
      @include Ellipsis;
      @include Transition;
      max-width: 211px;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &__subname {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $gray-7;
    }
    &__data {
      margin-left: auto;
      display: flex;
      flex-direction: column;
    }
    &__risk {
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin-left: auto;
      font-weight: 400;
      font-size: 12px;
      line-height: 144%;

      &__icon {
        margin-right: 2px;
        width: 14px;
        height: 14px;
      }
    }
    &__transactions {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: $gray-7;
    }
    &.active {
      background: $gray-3;
      border: 1px solid $main;
    }
    &.active &__name {
      color:$main;
      font-weight: 500;
    }
  }
  &-btn {
    margin-top: 40px;
    height: 40px;
    width: 360px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: $max-lg-media) {
  .aml {
    &-btn {
      width: 100%;
    }
    .item {
      width: 300px;
    }
    &-item {
      width: 287px;
      &__name {
        max-width: 150px;
      }
    }
    &-list {
      &_wrapper {
        width: 300px;
      }
      width: 300px;
    }
    &-content {
      padding-left: 24px;
      max-width: calc(100% - 180px);
    }
  }
}
$aml-content-change: 778px;
@media screen and (max-width: $aml-content-change) {
  .aml {
    &-content {
      padding-left: 12px;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .aml {
    flex-direction: column;
    height: 100%;
    &-title_wrapper {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
    }
    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $gray-8;
    }
    &-count {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
    &-sidebar {
      overflow: hidden;
      &__additional {
        margin-top: 40px;
      }
    }
    &-list {
      max-height: none;
      height: auto;
      width: 100%;
      margin-right: 0;
      overflow-y: auto;
      &_wrapper {
        width: 100%;
        height: auto;
      }
    }
    &-btn {
      margin-top: 24px;
    }
    &-item {
      width: 100%;
      margin-bottom: 4px;
      background-color: $gray-1;
      border: 1px solid #E8E9F0;
      &-logo {
        margin-right: 10px;
      }
    }
    &-content {
      padding: 0 1px;
      max-width: 100%;
    }
    &__additional {
      margin-top: 40px;
    }
  }
}
@media (min-width: $md-media) and (max-width: $xxl-media) {
  .aml {
    &-list_line {
      width: 1px;
      background: $gray-4;
    }

  }
}
@media screen and (max-width: $sub-sm-media) {
  .aml-item__name {
    max-width: 90px;
  }
}