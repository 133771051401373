.address-copy-dropdown {
  height: 28px;
  &__address {
    @include Transition;
    cursor: pointer;
    margin-top: 2px;
    padding: 0 8px;
    background: $gray-3;
    border-radius: 16px;
    font-size: 16px;
    line-height: 24px;
    &:hover {
      color: $main;
    }
  }
  & .bn-dropdown__overlay {
    position: relative;
    padding: 0;
    top: 12px;
    right: calc(100% + 26px);
    width: 200px;
    background-color: $gray-1;
    border-radius: 8px;
    box-shadow: 0px 8px 28px 8px rgba(0, 0, 0, 0.08), 0px 2px 6px -4px rgba(0, 0, 0, 0.14);
    div {
      display: flex;
      align-items: end;
      padding: 12px 8px;
      overflow-wrap: break-word;
      font-size: 12px;
      line-height: 16px;
      span {
        width: 160px;
      }
      svg {
        margin-left: auto;
      }
    }
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      top: -6px;
      right: 16px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent $gray-1 transparent;
    }
  }
}
