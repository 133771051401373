.dashboard-risk-score {
  position: relative;
  width: 384px;
  height: 312px;
  padding: 24px;
  background: $gray-1;
  border: 1px solid $gray-4;
  border-radius: 32px 8px 8px 8px;

  & .dashboard-card__title {
    padding: 0;
  }
  & .dashboard-card__content {
    position: relative;
  }

  &__wrapper {
    position: relative;
  }
  &__header {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__title {
      margin-right: 6px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      transform: matrix(1, 0, -0.01, 1, 0, 0);
    }
    span {
      width: 16px;
      height: 16px;
    }
    svg, path {
      fill: $gray-7;
    }
    svg {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  &__progress {
    position: relative;
    display: flex;
    justify-content: center;
    & > svg {
      width: 240px;
    }
    &.showShield {
      text {
        display: none;
      }
    }
    &__shield {
      position: absolute;
      top: 80px;

      width: 37.6px;
      height: 37.6px;
      & > svg {
        width: 37.6px;
        height: 37.6px;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 24px;
    right: 0;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $gray-8;
    transform: matrix(1, 0, -0.01, 1, 0, 0);
    &__link {
      &.transparent {
        height: 40px;
        border: 1px solid $gray-5;
        border-radius: 40px;
        span {
          color: $gray-8;
          font-size: 14px;
          line-height: 22px;
        }
        &:hover {
          border: 1px solid $gray-5;
          background: $gray-2;
        }
      }


    }
    .message {
      max-width: 288px;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
    }
    .error {
      color: $red-6;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .dashboard-risk-score {
    width: 100%;

    &__wrapper {
      width: 100%;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-risk-score {
    z-index: 1;
    width: 100%;
    height: 100px;
    padding: 16px 110px 16px 16px;
    border-radius: 4px;
    &__wrapper {
      width: 100%;
      margin-bottom: 0;
      position: relative;
    }
    &__header {
      align-items: center;
      justify-content: left;
      margin-bottom: 0;
      &.center {
        text-align: center;
        height: auto;
      }
      &__title {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 22px;
        justify-content: right;
      }
    }
    &__progress {
      z-index: 1;
      padding: 12px;
      position: absolute;
      border-radius: 50%;
      top: -65px;
      right: -98px;
      height: 104px;
      width: 104px;
      background-color: $gray-1;
      &__border {
        z-index: 0;
        position: absolute;
        border-radius: 50%;
        top: -15px;
        right: 12px;
        width: 106px;
        height: 106px;
        background-color: $gray-4;
      }
      &__shield {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        & > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &__footer {
      position: relative;
      align-items: baseline;
      top: 0;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      .message {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 2px;
      }
      a {
        font-size: 12px;
        line-height: 16px;
        color: $main;
        text-decoration: underline;
      }
    }
  }
}
