.aml-public {
  &-page {
    padding: 40px 0;
    min-height: 100vh;
    width: 100%;
    background: $gray-light;
  }
  &-box {
    position: relative;
    border: 1px solid $gray-4;
    border-radius: 8px;
    padding: 36px 40px;
    margin: auto;
    width: 595px;
    background: $gray-1;
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__header {
    display: flex;
    justify-content: center;
  }
  &__logo {
    width: 101px;
    height: 32px;
    svg {
      width: 101px;
      height: 32px;
    }
  }
  &__item {
    width: 100%;
    padding: 8px 16px 10px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    &:not(:first-child) {
      border-top: 1px solid $gray-3;
    }
    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      text-transform: uppercase;
      color: $gray-8;
    }
    &__value {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__user {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }
  &__wallet {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }
  &__risk {
    background: rgba(89, 189, 131, 0.15);
    border-radius: 0 0 2px 2px;
  }
  &__sources {
    margin-top: 30px;
    margin-left: 16px;
    .aml-single__sources__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $gray-8;
      text-transform: uppercase;
    }
  }
  &__radius-pie {
    position: relative;
    width: 230px;
    height: 230px;
    &__risk-score {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    &__percentage {
      font-weight: 500;
      font-size: 40px;
      line-height: 40px;
      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
      }
    }
    &__risk-score-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: $gray-8;
    }
  }
  &__pie-sources {
    margin-top: 30px;
    &__table {
      font-weight: 400;
      line-height: 22px;
      font-size: 14px;
      color: $gray-9;
    }
    &__title {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray-8;
    }
    tr:not(:last-child) {
      td {
        padding-bottom: 8px;
      }
    }
  }
  &__sources-detail {
    margin-top: 38px;
    &:not(:first-child) {
      margin-top: 22px;
    }
    &__top {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__dot {
      z-index: 2;
      top: 4px;
      left: 16px;
      width: 12px;
      height: 12px;
      position: absolute;
      border-radius: 50%;
    }
    &__name {
      z-index: 1;
      padding-left: 36px;
      padding-right: 16px;
      font-size: 12px;
      line-height: 22px;
      color: $gray-8;
      text-transform: uppercase;
      background-color: $gray-1;
    }
    &__ratio {
      padding-left: 16px;
      z-index: 1;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      background-color: $gray-1;
    }
    &__line {
      z-index: 0;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: calc(100% - 36px);
      height: 1px;
      background: $gray-3;
    }
    &__sources {
      width: 100%;
      margin-top: 25px;
      margin-left: 36px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > * {
        width: 50%;
      }
    }
    &__item {
      margin-bottom: 16px;
      width: calc(100% - 36px);
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
  }
  &__certification {
    z-index: 1;
    padding-right: 20px;
    background: $gray-1;
    margin-left: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    &__cams {
      margin-right: 16px;
      width: 55.92px;
      height: 44px;
    }
    &__cfcs {
      width: 40.33px;
      height: 44px;
    }
  }
  &__social {
    z-index: 1;
    background: $gray-1;
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    & > * {
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 9px;
      }
    }
  }
  &__download {
    position: absolute;
    top: 40px;
    right: 56px;
    &__icon {
      margin-left: 16px;
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
      svg, path {
        fill: $gray-8
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .aml-public {
    &-page {
      padding: 0;
      background: $gray-1;
    }
    &-box {
      border: none;
      padding: 28px 8px 26px 8px;
      width: 100%;
      &__top {
        padding: 0 8px;
      }
    }
    &__header {
      display: none;
    }
    &__item {
      padding: 16px 8px 8px 8px;
      border-top: 1px solid $gray-3;
      &:not(:first-child) {
        padding-top: 16px;
      }
      &__title {
        margin-bottom: 2px;
      }
      &__value {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__user, &__wallet {
      .aml-public__item {
        flex-direction: column;
        align-items: baseline;
      }
    }
    &__user {
      margin-top: 0;
      .aml-public__item {
        &:first-child {
          padding-top: 0;
          border-top: none;
        }
      }
    }
    &__wallet {
      margin-top: 0;
    }
    &__risk.aml-public__item {
      padding-top: 8px;
      padding-right: 16px;
      padding-bottom: 9px;
      .aml-public__item__title {
        margin-bottom: 0;
      }
      .aml-public__item__value {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
    &__sources {
      flex-direction: column-reverse;
      margin-left: 0;
      margin-top: 35px;
    }
    &__pie-sources {
      margin: 20px auto 0 auto;
      min-width: 256px;
    }
    &__radius-pie {
      margin: 0 auto;
    }
    &__sources-details {
      margin-top: 34px;
    }
    &__sources-detail-mobile {
      padding: 13px 16px 15px 16px;
      border-radius: 8px;
      &:not(:first-child) {
        margin-top: 16px;
      }
      &.danger {
        background: #FEECED;
        .aml-public__sources-detail-mobile__name, .aml-public__sources-detail-mobile__ratio {
          background: #FEECED;
        }
      }
      &.suspicious {
        background: #FFF5F5;
        .aml-public__sources-detail-mobile__name, .aml-public__sources-detail-mobile__ratio {
          background: #FFF5F5;
        }
      }
      &.trusted {
        background: #EFF8F2;
        .aml-public__sources-detail-mobile__name, .aml-public__sources-detail-mobile__ratio {
          background: #EFF8F2;
        }
      }
      &__sources {
        margin-left: 0;
      }
      &__item {
        margin-bottom: 22px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-child {
          margin-top: 0;
          margin-bottom: 20px;
          .aml-public__sources-detail-mobile__name {
            font-size: 12px;
            line-height: 22px;
            text-transform: uppercase;
            color: $gray-8;
          }
          .aml-public__sources-detail-mobile__ratio {
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }

      }
      &__name {
        padding-right: 16px;
        z-index: 1;
        font-size: 14px;
        line-height: 18px;
        color: $gray-9;
      }
      &__ratio {
        width: 50px;
        padding-left: 16px;
        z-index: 1;
        font-size: 14px;
        line-height: 18px;
      }
      &__line {
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 20px);
        height: 1px;
        background: $gray-4;
      }
    }
    &__footer {
      margin-top: 40px;
    }
    &__certification {
      margin-left: 8px;
      &__cams {
        margin-right: 12px;
        width: 30.5px;
        height: 24px;
      }
      &__cfcs {
        width: 24px;
        height: 26px;
      }
    }
    &__social {
      padding-right: 16px;
    }
  }
}