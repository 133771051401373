.bn-dropdown {
  position: relative;
  &-navigation {
    position: relative;
  }
  &__appearance {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__overlay {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    position: absolute;
    z-index: 20;
    top: 49px;
    right: 0;
    width: 282px;
    background: $gray-3;
    color: $gray-9;
    font-size: 16px;
    line-height: 22px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 8px 8px;
    transform: translateY(10px);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
    &.active {
      transform: translate(0, 0);
      opacity: 1;
      pointer-events: all;
    }
  }
  &_btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
