@mixin NavItemLinkText {
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.sidebar {
  flex: 0 0 200px !important;
  width: 268px !important;
  max-width: 268px !important;
  min-width: 268px !important;
  border-right: 1px solid $gray-4;
  &__logo {
    display: inline-flex;
    margin-left: 40px;
    svg {
      height: auto;
      width: 101px;
    }
  }
  &__nav {
    width: auto;
    margin: 40px 16px 40px 18px;
  }
  &-nav-item {
    border-radius: 4px 16px 16px 4px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:hover:not(.disableHover) {
      background-color: $gray-light;
    }
    &.active {
      background: $gray-2;
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        background: $gray-2;
      }
    }
    &-link {
      padding: 16px 16px 16px 24px;
      @include NavItemLinkText;
      display: flex;
      flex-direction: row;
      &__active {
        font-weight: 500;
        color: $pink;
      }
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
      margin-right: -8px;
      margin-left: 14px;
      margin-top: 2px;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 0 16px 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 16px 16px 16px 24px;
      border-radius: 12px;
    }
  }
  &-nav-collapse {
    &-header {
      @include NavItemLinkText;
      &.open {
        font-weight: 500;
      }
      &.active {
        font-weight: 500;
        color: $pink !important;
      }
    }
    &-plus {
      z-index: 1000;
      margin-right: 0 !important;
      position: absolute;
      right: 16px;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      line-height: 24px;
      cursor: pointer;

      path {
        fill: $gray-9;
      }
    }
    &-accounts {
      max-height: 120px;
      transition: all .3s ease-in;
      overflow-y: hidden;
      &.showAll {
        max-height: 299px;
        overflow-y: scroll;
      }
      &__wrapper {
        overflow-y: hidden;
        position: relative;
        padding-left: 7px;
        &:after {
          content: "";
          width: 1px;
          height: calc(100% - 17px);
          position: absolute;
          left: 0;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: $gray-4;
        }
      }
    }
    &-show-all.default-btn.transparent {
      margin-left: 7px;
      height: 24px;
      width: 212px;
      padding: 2px;
      margin-top: 10px;
      border: 1px solid $gray-4;
      border-radius: 12px;
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
      &:hover {
        background-color: $gray-2;
        border: 1px solid $gray-4;
      }
    }
  }
  &-bottom {
    margin-top: auto;
    padding: 0 40px;
    &__current-plan {
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
      text-align: center;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $gray-9;
      }
    }
    &__upgrade-plan {
      margin-top: 8px;
      width: 100%;
      button {
        height: 40px;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        padding: 9px 16px;
        border-radius: 40px;
      }
    }
    &__lang {
      margin-top: 40px;
    }
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-layout-sider-children {
    top: 0;
    padding: 0;
    max-height: 100vh;
    position: sticky;
    display: flex;
    flex-direction: column;
    overflow-y:  auto;
    & > .scrollbar-container {
      padding: 40px 0;
      display: flex;
      flex-direction: column;
    }
  }
  &.onboardingIsRun {
    z-index: 1001;
    pointer-events: none;
  }
}

.sidebar-account {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  width: 212px;
  height: 36px;
  background: $gray-1;
  border-radius: 2px 8px 8px 2px;
  cursor: pointer;
  transition: border 0.3s ease;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
  &:hover {
    background-color: $gray-light;
  }
  &.active {
    background: $gray-2;
  }
  &-logo {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    &__wrapper {
      position: relative;
      line-height: 16px;
    }
    &__sync-badge {
      width: 12px;
      height: 12px;
      position: absolute;
      background: $gray-1;
      border-radius: 50%;
      bottom: -4px;
      right: 4px;
      & .sync-loader, & .error {
        width: 9px;
        height: 9px;
      }
    }
  }
  &.active &-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $pink;
  }
  &-title {
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-risk {
    margin-left: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    &.error {
      font-weight: 500;
      color: $red-6;
    }
    &.warning {
      font-weight: 500;
      color: $red-3;
    }
    &.ok {
      color: $green-3;
    }
  }
}
.sidebar-aml {
  &__additional {
    margin-left: 7px;
    margin-top: 10px;
  }
  &__manual-checks, &__archive {
    width: 212px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 6px 6px 6px 10px;
    border-radius: 2px 8px 8px 2px;
    @include Transition;
    &:hover {
      background-color: $gray-light;
    }
    svg {
      width: 16px;
      height: 16px;
    }
    & .title {
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    &.active {
      background: $gray-2;
      & .title {
        color: $pink;
        font-weight: 500;
      }
    }
  }
  &__archive {
    margin-top: 4px;
  }
}


@media screen and (max-width: $min-show-sidebar-media) {
  .sidebar-nav-collapse-plus {
    margin-right: -12px !important;
  }
}
