
@mixin ActiveLink {
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $pink;
  }
}

.header-wrapper {
  @include Transition;
  position: absolute;
  background: $gray-1;
  width: 100%;
  height: 80px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  z-index: $header-index;

  .header-mobile {
    display: none;
  }
  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    height: 100%;
    &-logo {
      margin-right: 116px;
    }

    &-logo_mobile {
      display: none;
    }
    &-navigation {
      width: 100%;
    }
    &-navigation ul {
      display: flex;
      align-items: center;
      .navigation-item-link {
        margin-right: 40px;
        span {
          white-space: nowrap;
          color: $gray-8;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        &__active {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $gray-9;
          span {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            color: $gray-9;
            font-weight: 500;
            @include ActiveLink;
          }
        }
        svg {
          display: none;
        }
      }
      .navigate-item-upgrade-plan {
        margin-left: auto;
        &__link {
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $pink;
          }
        }
        &__active {
          span {
            position: relative;
            @include ActiveLink;
          }
        }
      }
    }

    &-right {
      margin-left: auto;
      svg {
        display: none;
      }
    }

    &-btn {
      background: inherit;
      border: none;
      cursor: pointer;
    }
  }
}

.headerScrollTop {
  position: fixed;
  top: -80px;
}

.headerFixed {
  position: fixed;
  top: 0;
}

.headerStatic {
  position: static;
}

.headerBoxShadowOff {
  box-shadow: none;
}

.headerHideMobile {
  display: none;
}