.mobile-tab-menu {
  width: 100%;
  z-index: 1001;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 8px 8px 0 0;
  background: #F3F4F9;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  &__item {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__link {
    svg {
      width: 24px;
      height: 24px;
    }
    &.active {
      svg path {
        fill: $pink;
      }
    }
  }
  &__additional {
    width: 48px;
    height: 48px;
    background: $gray-3;
    svg path {
      fill: $gray-8;
      @include Transition;
    }
    &__overlay {
      width: 272px;
      padding: 0;
      background: $gray-2;
      top: auto;
      bottom: 64px;
      right: -42px;
      color: $gray-8;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px 0 0 0;
      overflow: hidden;
      transition: all .3s ease-in-out;

      &.active {
        transform: translateX(-42px);
      }
      &__link {
        padding: 12px 24px;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        &:not(:last-child) {
          border-bottom: 1px solid #E8E9F0;
        }
        &:hover {
          opacity: 0.8;
        }
        &.active {
          color: $main;
          background: $gray-5;
        }
      }
    }
    &.active {
      svg path {
        fill: $pink;
      }
    }
  }
  &__drawer {
    .drawer-content {
      background-color: $gray-1;
      height: 100%;
      padding: 24px 16px;
    }
    &__content {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__header {
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__logo {
      width: 60px;
      height: 18px;
      margin-bottom: 16px;
    }
    &__username {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $gray-7;
      margin-bottom: 2px;
    }
    &__current-plan {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
      margin-bottom: 16px;
      cursor: pointer;
      span {
        text-decoration: underline;
        margin-left: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $pink;
      }
    }
    &__links {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &__link {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 22px;
      svg {
        margin-right: 16px;
        width: 24px;
        height: 24px;
      }
      svg path {
        fill: $gray-9
      }
    }
  }
}
