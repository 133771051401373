.create-document__step-one {
  background: $gray-light;

  &__add-btn-wrapper {
    display: none;
  }
  &__text {
    margin: 24px 0 16px 0;
    font-size: 16px;
    line-height: 24px;
    color: $gray-8;
  }
  &__all-check {
    margin-bottom: 24px;
    &-text {
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $gray-9;
      border-bottom: 1px solid $gray-9;
    }
  }
  &__check-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    max-width: 918px; // 894 + 24
    width: 100%;
  }
  .check-content-wrapper {
    margin-right: 8px;
    width: 70%;
  }
  &__check-item-wrapper {
    width: 305px;
    padding: 0 12px;
    margin-bottom: 8px;
    &.add-account {
      height: 66px;
      display: flex;
      align-items: center;
    }
  }
  &__check {
    display: flex;
    align-items: center;
    padding: 14px 10px 10px 14px;
    background: $gray-1;
    border: 1px solid $gray-5;
    border-radius: 4px;
    cursor: pointer;
    .sync-loader {
      margin-right: 8px;
      width: 26px;
      height: 26px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .check-icon-wrapper {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
    .sync-icon-wrapper {
      position: relative;
      margin-right: 8px;
      width: 32px;
      height: 32px;
      .sync-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .check-checkbox-wrapper {
      margin-left: auto;
    }
    .check-title {
      @include Ellipsis;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      color: $gray-9;
    }
    .check-text {
      //@include Ellipsis;
      //width: 100%;
      font-size: 14px;
      line-height: 16px;
      color: $gray-6;
      &--synchronizing {
        color: $main;
      }
      &--error {
        color: $red-5;
      }
    }
    &--manual {
      width: 282px;
      .check-title {
        overflow: inherit;
        width: auto;
        font-size: 14px;
      }
    }
  }
  &__line {
    margin: 16px 0 24px 0;
    border: 1px solid $gray-3;
  }
  &__add-btn {
    position: absolute;
    right: 16px;
    top: 56px;
  }
  &__add-account {
    width: 100%;
    height: 40px;
    @include TransparentBtn;
  }
}

