.aml-activate {
  &-dropdown {
    & .bn-dropdown__overlay {
      left: 0;
      right: auto;
      width: 316px;
      padding: 16px;
      background-color: $gray-1;
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-color: $gray-4;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
      border-radius: 0 0 16px 16px;
    }
  }
  &-packs {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__subscriptions {
      margin: 24px 0 16px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
  &-pack {
    width: 100%;
    padding: 6px 16px 8px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-4;
    }

    @include Transition;
    &:hover {
      background-color: $gray-2;
    }
    &__left {
      display: flex;flex-direction: column;
    }
    &__check {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      font-weight: 900;
      font-size: 28px;
      line-height: 32px;
      color: $pink;
      span {
        margin-left: 7px;
        font-weight: 900;
        font-size: 20px;
        line-height: 32px;
      }
    }
    &__per-check {
      font-size: 14px;
      line-height: 16px;
      color: $gray-8;
    }
    &__price {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      &__currency {
        margin: 0 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }

  }
  &-modal {
    &__radio-group {
      width: 100%;
    }
    &__radio {
      width: 100%;
      display: flex;
      align-items: center;
      &.ant-radio-wrapper {
        margin-right: 0;
      }
      span.ant-radio, span.ant-radio > .ant-radio-input, span.ant-radio > .ant-radio-inner,  {
        height: 24px;
        width: 24px;
      }
      span.ant-radio > .ant-radio-inner::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;

        border: 1px solid $blue-2;
        border-radius: 50%;
      }
      span:not(.ant-radio) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__next {
      width: 100%;
      height: 40px;
      margin-top: 24px;
    }
  }
}
