.dashboard-public-account {
  position: relative;
  height: 200px;
  padding: 24px 16px 16px 40px;
  &__header {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__icon {
      position: relative;
      width: 56px;
      height: 56px;

      &__skeleton {
        border-radius: 50%;
        background-color: $gray-3;
        width: 56px;
        height: 56px;
      }
      & > img {
        width: 56px;
        height: 56px;
      }
      & .account-beta-badge {
        top: -4px;
        right: -12px;
      }
    }
    &__data {
      margin-left: 16px;
      &__top {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      &__const {
        margin-top: 4px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: $gray-8;
      }
      &__name {
        min-height: 32px;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
  &__last-sync-date {
    position: absolute;
    bottom: 16px;
    right: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $gray-7;
  }
}

@media screen and (max-width: $max-sm-media) {
  .dashboard-public-account {
    border: none;
    background: none;
    height: 94px;
    padding: 8px 8px 16px 8px;
    margin-bottom: 24px;
    &__header {
      &__icon {
        width: 40px;
        height: 40px;
        &__skeleton {
          width: 40px;
          height: 40px;
        }
        & > img {
          width: 40px;
          height: 40px;
        }
      }
      &__data {
        &__const {
          margin-top: 0;
          font-size: 14px;
          line-height: 22px;
        }
        &__name {
          font-size: 16px;
          line-height: 24px;
          min-height: 24px;
        }
      }
      &__right {
        align-self: flex-start;
      }
    }
    &__last-sync-date {
      right: auto;
      left: 8px;
      bottom: -6px
    }
  }
}
